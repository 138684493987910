import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { checkPasswordValidator } from '@/core/validators';
import { emailValidator } from '@/modules/registration/validators/registration.validator';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
import { VERIFY_REGISTRATION_ROUTE } from '../routes';
export default defineComponent({
    name: 'registration-form-component',
    emits: ['toggle-form', 'next-register-form', 'reset-form-state'],
    props: {
        showLoginForm: {
            type: Boolean,
            required: true
        },
        showNextRegForm: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            validEmail: false,
            userForm: {
                marketingOptIn: false,
                tcOptIn: false
            },
            submitting: false,
            rules: {
                email: [
                    {
                        required: true,
                        // eslint-disable-next-line no-unused-vars
                        validator: (rule, value, cb) => {
                            emailValidator(rule, value, (err) => {
                                if (err) {
                                    this.validEmail = false;
                                    cb(err);
                                }
                                else {
                                    this.validEmail = true;
                                    cb();
                                }
                            });
                        },
                        trigger: 'change'
                    }
                ],
                firstName: [
                    { required: true, message: 'Enter your first name.', trigger: 'blur' },
                    {
                        max: 255,
                        message: 'Please enter a maximum of 255 characters.',
                        trigger: 'blur'
                    },
                ],
                lastName: [
                    { required: true, message: 'Enter your last name.', trigger: 'blur' },
                    {
                        max: 255,
                        message: 'Please enter a maximum of 255 characters.',
                        trigger: 'blur'
                    },
                ],
                password: [
                    { required: true, validator: checkPasswordValidator, trigger: 'blur' }
                ],
                confirmPassword: [
                    {
                        required: true,
                        // eslint-disable-next-line no-unused-vars
                        validator: (rule, value, cb) => {
                            if (value?.length > 0) {
                                if (value !== this.userForm.password) {
                                    cb(new Error('Password does not match.'));
                                }
                                else {
                                    cb();
                                }
                            }
                            else {
                                cb(new Error('Confirm password is required.'));
                            }
                        },
                        trigger: 'blur',
                    }
                ],
            },
            emptyEmail: true,
        };
    },
    created() {
    },
    computed: {
        registerButtonLabel() {
            const { showNextRegForm } = this;
            return showNextRegForm ? 'REGISTER' : 'NEXT';
        },
        readyToSubmit() {
            const { userForm } = this;
            const fields = Object.keys(userForm);
            let value = false;
            let requiredCount = 5;
            fields.forEach((field) => {
                const fieldValue = userForm[field];
                if (typeof fieldValue === 'string' && fieldValue !== '') {
                    requiredCount -= 1;
                }
            });
            if (!requiredCount) {
                value = true;
            }
            if (!userForm.tcOptIn) {
                value = false;
            }
            return value;
        }
    },
    methods: {
        ...mapActions(AUTHENTICATION_STORE, ['register']),
        nextRegisterForm() {
            this.$emit('next-register-form');
        },
        toggleForm() {
            this.$emit('toggle-form');
        },
        resetFormState() {
            this.$emit('reset-form-state');
        },
        returnToLogin() {
            this.resetFormState();
            this.$router.push('/login');
        },
        submitRegistration() {
            const { $refs, userForm } = this;
            $refs.registrationForm.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    this.register(userForm)
                        .then((user) => {
                        ElNotification.success({
                            message: 'Successfully registered your account.'
                        });
                        this.resetFormState();
                        this.$router.push({ name: VERIFY_REGISTRATION_ROUTE, params: { email: user.email } });
                    })
                        .catch((error) => {
                        this.$notify.error({
                            title: 'Error',
                            message: error?.data?.message
                        });
                    })
                        .finally(() => {
                        this.submitting = false;
                    });
                }
                else {
                    ElNotification.error({
                        message: 'Please fill in the required fields.'
                    });
                }
            });
        },
    }
});
