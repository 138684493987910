<template>
  <el-form
    v-if="valid && !successChangePassword"
    class="change-password-form form show"
    :model="changePassword"
    ref="changePasswordForm"
    :rules="rules"
    size="small"
    label-position="top"
    status-icon
    :hide-required-asterisk="true"
  >
  <div class="input-section">
    <el-form-item prop="newPassword">
      <template v-slot:label>
        <span>
          <strong>New password</strong>
          <el-popover placement="bottom" width="280" trigger="click">
            <template v-slot>
              <span class="create-password">
                <h4>Password Requirements:</h4>
                <ul class="password-required">
                  <li>Requires 1 uppercase character</li>
                  <li>1 lowercase chararacter</li>
                  <li>1 symbol: = + - ^ $ * . [ ] { } ( ) ? " ! @ # % &amp; / \ , &gt; &lt; ' : ; | _ ~ `</li>
                  <li>A minimum of 8 characters</li>
                </ul>
              </span>
            </template>
            <template v-slot:reference>
              <el-icon><InfoFilled /></el-icon>
            </template>
          </el-popover>
        </span>
      </template>
      <el-input type="password" v-model="changePassword.newPassword" placeholder="Create a password"></el-input>
    </el-form-item>
    <el-form-item label="Confirm password" prop="confirmPassword">
      <el-input type="password" v-model="changePassword.confirmPassword" placeholder="Confirm your new password"></el-input>
    </el-form-item>
   </div>
    <el-row class="action-section" >
      <el-col :md="24" class="flex-column">
        <el-button
          type="primary"
          class="yellow-button next-button"
          round
          :loading="submitting"
          :disabled="!changePassword.newPassword || !changePassword.confirmPassword"
          @click="doSubmit"
          >NEXT</el-button
        >
        <p class="alternate-login-link default-text">Already have an account? <a href="#" @click="returnToLogin" class="default-link">Log in</a></p>
      </el-col>
    </el-row>
  </el-form>
  <div v-else-if="!valid">
    <p class="info">Your recovery link is already expired. You need to try again <el-button class="default-link" type="text" @click="returnToForgotPassword">here.</el-button></p>
  </div>

  <div v-if="successChangePassword" class="reset-password-confirmation-wrapper change-password-confirmation-wrapper">
    <div class="confirmation-img-wrapper">
      <inline-svg :src="require(`@/assets/login/change-password-confirmation.svg`)" ></inline-svg>
    </div>
    <el-row class="action-section" >
      <el-col :md="24">
        <el-button
          type="primary"
          class="yellow-button next-button"
          round
          @click="returnToLogin"
          >DONE</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { InfoFilled } from '@element-plus/icons';
import * as CryptoJS from 'crypto-js';
import { defineComponent } from 'vue';

import { AUTHENTICATION_STORE } from '@/store/modules/authentication';

export default defineComponent({
  name: 'change-password',

  emits: ['toggle-changed-password', 'reset-form-state'],

  components: {
    InfoFilled
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.$route.query.token) {
        next('403');
      } else {
        vm.verifyToken();
      }
    });
  },
  data() {
    const passwordValidator = (rule, value, cb) => {
      if (!value || value.length === 0) {
        return cb(new Error('Password is required.'));
      }

      // eslint-disable-next-line no-useless-escape
      if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\=\+\-\^\$\*\.\[\]\{\}\(\)\?\"\!\@\#\%\&\/\\\,\>\<\'\:\;\|\_\~\`])(?!.*\s).{8,}$/.test(value)) {
        return cb(new Error('Requires a minimum of 8 total characters, with 1 upper case, 1 lower case and 1 symbol'));
      }
      return cb();
    };
    const checkConfirmPassword = (rule, value, cb) => {
      if (!value || value.length > 0) {
        if (value !== this.changePassword.newPassword) {
          return cb(new Error('Password does not match.'));
        }
        return cb();
      }
      return cb(new Error('Confirm password is required.'));
    };

    return {
      rules: {
        newPassword: [
          { required: true, validator: passwordValidator, trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, validator: checkConfirmPassword, trigger: 'blur' },
        ]
      },
      changePassword: {
        newPassword: '',
        confirmPassword: '',
      },
      valid: false,
      successChangePassword: false,
      submitting: false,
    };
  },

  async created() {
    await this.verifyToken();
  },

  methods: {
    async verifyToken() {
      if (this.$route.query.token) {
        await this.$store.dispatch(`${AUTHENTICATION_STORE}/verifyResetPasswordToken`, this.$route.query.token)
          .then(() => {
            this.valid = true;
          })
          .catch((e) => {
            this.$notify.error({
              message: e.response.data.message || 'Url is expired.'
            });
          });
      }
    },
    doSubmit() {
      // eslint-disable-next-line consistent-return
      this.$refs.changePasswordForm.validate((valid) => {
        if (valid) {
          const password = CryptoJS.AES.encrypt(this.changePassword.newPassword, process.env.VUE_APP_CRYPTO_SECRET_KEY).toString();
          this.submitting = true;

          this.$store.dispatch(`${AUTHENTICATION_STORE}/changePassword`, { password })
            .then(() => {
              this.$notify.success({
                message: 'You successfully changed your password.'
              });
              this.successChangePassword = true;
              this.toggleResetEmail();
            })
            .catch((e) => {
              this.$notify.error({
                title: 'Error',
                message: e?.data?.message || 'Error changing your password.'
              });
            })
            .finally(() => {
              this.submitting = false;
            });
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Please provide a valid matching passwords.'
          });
        }
      });
    },

    returnToForgotPassword() {
      this.resetFormState();
      this.$router.push('/forgot-password');
    },

    returnToLogin() {
      this.resetFormState();
      this.$router.push('/login');
    },

    resetFormState() {
      this.$emit('reset-form-state');
    },

    toggleResetEmail() {
      this.$emit('toggle-changed-password');
    }
  }
});
</script>
<style lang="scss" scoped>
@import '@/modules/login/styles/login.scss';

.info {
  color: #888;
}
</style>
