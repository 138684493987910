import { ElNotification } from 'element-plus';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { LOGIN_ROUTE } from '@/modules/login/routes';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default defineComponent({
    name: 'verify-form-component',
    data() {
        return {
            verifyForm: {
                code1: '',
                code2: '',
                code3: '',
                code4: '',
            },
            submitted: false,
            verified: false,
            resendCountdown: 60,
            showResendButton: true,
            email: '',
            codeCompleteCount: 4
        };
    },
    created() {
        const { $route } = this;
        const { params } = $route;
        if (params.resendCode) {
            this.resendCode();
        }
        if (params.email) {
            this.email = params.email;
        }
    },
    methods: {
        ...mapActions(AUTHENTICATION_STORE, ['resendVerificationCode', 'verifyVerificationCode']),
        setResendCountdown() {
            if (this.resendCountdown > 0) {
                setTimeout(() => {
                    this.resendCountdown -= 1;
                    this.setResendCountdown();
                }, 1000);
            }
            else {
                this.showResendButton = true;
            }
        },
        resendCode() {
            this.showResendButton = false;
            this.setResendCountdown();
            this.resendVerificationCode(this.$route.params.email)
                .then(() => {
                ElNotification.success({
                    title: 'Success',
                    message: 'Verification code has been sent to your email.'
                });
            })
                .catch((error) => {
                ElNotification.error({
                    title: 'Error',
                    message: error.response.message
                });
            });
        },
        navigateToLogin() {
            this.$router.push('/login');
        },
        inputAutoFocus(payload) {
            const { $refs } = this;
            const { index } = payload;
            const nextIndex = index + 1;
            const field = $refs[`code${nextIndex}`];
            if (field) {
                field.focus();
            }
            this.inputNumber(index, true);
        },
        inputNumber(value, checkIfCompleteOnly = false) {
            const { verifyForm, codeCompleteCount } = this;
            const fields = Object.keys(verifyForm);
            let complete = 1;
            let liveCount = 0;
            for (let i = 0; i < fields.length; i++) {
                const name = fields[i];
                const field = this.verifyForm[name];
                if (field === '' && !checkIfCompleteOnly) {
                    this.verifyForm[name] = value;
                    break;
                }
                if (checkIfCompleteOnly && field) {
                    liveCount += 1;
                }
                else {
                    complete += 1;
                }
            }
            if (complete === codeCompleteCount || liveCount === codeCompleteCount) {
                this.submit();
            }
        },
        submit() {
            const { codeCompleteCount } = this;
            this.submitted = true;
            const data = {
                email: this.$route.params.email.toString(),
                code: Object.values(this.verifyForm).join().replaceAll(',', ''),
            };
            if (data.code.length === codeCompleteCount) {
                this.verifyVerificationCode(data)
                    .then(() => {
                    this.verified = true;
                    ElNotification.success({
                        title: 'Successful',
                        message: 'You successfully verified your account. You may now login.'
                    });
                    this.$router.push({ name: LOGIN_ROUTE });
                })
                    .catch(() => {
                    ElNotification.error({
                        title: 'Error',
                        message: 'Verification code does not match.'
                    });
                    this.verified = false;
                })
                    .finally(() => {
                    this.submitted = false;
                });
            }
        }
    },
});
