import * as CryptoJS from 'crypto-js';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
// import StringHelper from '@/core/helpers/string.helper';
import ChangePasswordFormComponent from '@/modules/change-password/components/ChangePasswordFormComponent.vue';
// import { CHANGE_PASSWORD_ROUTE } from '@/modules/change-password/routes';
import ForgotPasswordFormComponent from '@/modules/forgot-password/components/ForgotPasswordFormComponent.vue';
// import { FORGOT_PASSWORD_ROUTE } from '@/modules/forgot-password/routes';
import LoginFormComponent from '@/modules/login/components/LoginFormComponent.vue';
import RegistrationFormComponent from '@/modules/registration/components/RegistrationFormComponent.vue';
import VerifyFormComponent from '@/modules/registration/components/VerifyFormComponent.vue';
import { PROPERTIES_PROJECT_POST_STORE } from '@/store/modules/project-post';
import { UserWorkstationConstant } from '@/store/modules/user-workstation/constants';
export default defineComponent({
    name: 'login',
    components: {
        LoginFormComponent,
        RegistrationFormComponent,
        VerifyFormComponent,
        ForgotPasswordFormComponent,
        ChangePasswordFormComponent
    },
    data() {
        return {
            showLoginForm: true,
            showNextRegForm: false,
            hasResetEmailSent: false,
            hasChangedPassword: false,
            windowHeight: window.innerHeight,
        };
    },
    watch: {
        windowHeight(newHeight) {
            this.windowHeight = newHeight;
        }
    },
    computed: {
        welcomeDescription() {
            const { showNextRegForm, hasRegistered, isResetPassword, hasResetEmailSent, hasChangedPassword, isChangePassword } = this;
            let label = 'Welcome to iknowa';
            if (showNextRegForm) {
                label = 'We just need few process to proceed';
            }
            if (hasRegistered) {
                label = 'Please enter the code below to verify your email';
            }
            if (isResetPassword) {
                label = 'Enter your register email for password reset link';
            }
            if (isChangePassword) {
                label = 'Create new password';
            }
            if (hasResetEmailSent) {
                label = 'Check your email';
            }
            if (hasChangedPassword) {
                label = 'All done!';
            }
            return label;
        },
        hasRegistered() {
            const { $route } = this;
            return $route.params?.email;
        },
        isResetPassword() {
            const { $route } = this;
            // return $route.name === FORGOT_PASSWORD_ROUTE;
            // return $route.name === FORGOT_PASSWORD_ROUTE;
            return $route.name === 'forgot-password';
        },
        isChangePassword() {
            const { $route } = this;
            // return $route.name === CHANGE_PASSWORD_ROUTE;
            // return $route.name === CHANGE_PASSWORD_ROUTE;
            return $route.name === 'change-password';
        }
    },
    mounted() {
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        });
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.onResize);
    },
    created() {
        this.initialize();
    },
    methods: {
        ...mapActions(PROPERTIES_PROJECT_POST_STORE, [
            'getProjectMembers',
            'updateProjectMember'
        ]),
        resetFormState() {
            this.showLoginForm = true;
            this.showNextRegForm = false;
            this.hasResetEmailSent = false;
            this.hasChangedPassword = false;
        },
        toggleForm() {
            this.showLoginForm = !this.showLoginForm;
        },
        nextRegisterForm() {
            this.showNextRegForm = true;
        },
        toggleResetEmail() {
            this.hasResetEmailSent = !this.hasResetEmailSent;
        },
        toggleChangedPassword() {
            this.hasChangedPassword = !this.hasChangedPassword;
        },
        onResize() {
            this.windowHeight = window.innerHeight;
        },
        initialize() {
            this.checkIfHasProjectInvitation();
        },
        async checkIfHasProjectInvitation() {
            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.returnUrl) {
                const cleanParam = params.returnUrl.replace('/dashboard?', '');
                const queryParams = cleanParam.split('&');
                if (queryParams && queryParams.length) {
                    const [invToken, refId] = queryParams;
                    const cleanToken = invToken.replace('project_inv_token=', '');
                    const cleanProjectId = refId ? refId.replace('ref=', '') : '';
                    const projectId = cleanProjectId;
                    const projectInvToken = cleanToken;
                    if (!projectId || !projectInvToken) {
                        return;
                    }
                    await this.getProjectMembers(projectId).then((projectMembers) => {
                        if (projectMembers) {
                            const token = CryptoJS.AES.decrypt(decodeURIComponent(projectInvToken), process.env.VUE_APP_CRYPTO_SECRET_KEY).toString(CryptoJS.enc.Utf8);
                            const hasBeenInvited = projectMembers.find((projectMember) => {
                                return projectMember.email === token;
                            });
                            if (hasBeenInvited && hasBeenInvited.invitationStatus === UserWorkstationConstant.USER_WORKSTATION_STATE.PENDING) {
                                const payload = {
                                    projectId,
                                    projectMemberId: hasBeenInvited.id,
                                    formData: { memberPermissionId: hasBeenInvited.memberPermissionId, token: hasBeenInvited.token, isPoked: true }
                                };
                                this.updateProjectMember(payload);
                            }
                        }
                    });
                }
            }
        },
    }
});
