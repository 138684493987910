import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import EmailValidator from '@/core/utils/email-validation';
import { AUTHENTICATION_STORE } from '../../../store/modules/authentication';
export default defineComponent({
    name: 'forgot-password-form-component',
    emits: ['toggle-reset-email', 'reset-form-state'],
    data: () => {
        return {
            forgotPassword: {
                email: '',
            },
            submitting: false,
            sentInstructions: false,
            rules: {
                email: [
                    {
                        required: true,
                        validator: (rule, value, cb) => {
                            const isValidEmail = EmailValidator.validate(value);
                            if (isValidEmail) {
                                cb();
                            }
                            else {
                                cb(new Error('Invalid email address'));
                            }
                        },
                        trigger: 'change',
                    },
                ]
            },
        };
    },
    methods: {
        ...mapActions(AUTHENTICATION_STORE, ['resetPassword']),
        submit() {
            const { forgotPassword, $refs } = this;
            const { email } = forgotPassword;
            $refs.resetPasswordForm.validate((valid) => {
                if (valid) {
                    this.submitting = true;
                    this.resetPassword(email)
                        .then(() => {
                        this.$notify.success({
                            message: 'Password Recovery Instructions are sent to your email. Please check your email now.',
                        });
                        this.sentInstructions = true;
                        this.toggleResetEmail();
                    })
                        .catch((e) => {
                        this.$notify.error({
                            message: e.response?.data?.message
                                || 'Error sending password recovery, please try again.',
                        });
                        this.sentInstructions = false;
                    })
                        .finally(() => {
                        this.submitting = false;
                    });
                }
                else {
                    this.$notify.error({
                        message: 'Please fill in a valid email.'
                    });
                }
            });
        },
        returnToLogin() {
            this.resetFormState();
            this.$router.push('/login');
        },
        resetFormState() {
            this.$emit('reset-form-state');
        },
        toggleResetEmail() {
            this.$emit('toggle-reset-email');
        }
    },
});
