// import { User } from '@/core/packages/shared-library';
import CryptoJS from 'crypto-js';
import { defineComponent } from 'vue';
import { mapActions } from 'vuex';
import { AUTH_SESSION_EXPIRY, AUTH_USER, CRYPTO_SECRET_KEY } from '@/core/constants';
import EmailValidator from '@/core/utils/email-validation';
import mobile from '@/core/utils/mobile';
// import { ACCOUNT_SETUP_ROUTE } from '@/modules/account-setup/routes';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';
import { setLocalStorageWithExpiry } from '../../../core/utils/common';
import { ACCOUNT_ROLE_SETUP } from '../../account-role-setup/routes';
import { DASHBOARD_ROUTE } from '../../dashboard/routes';
import { VERIFY_REGISTRATION_ROUTE } from '../../registration/routes';
export default defineComponent({
    name: 'login-form-component',
    props: {
        showLoginForm: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            loginForm: {
                username: null,
                password: null,
            },
            rules: {
                username: [
                    {
                        required: true,
                        validator: (rule, value, cb) => {
                            const isValidEmail = EmailValidator.validate(value);
                            if (isValidEmail) {
                                cb();
                            }
                            else {
                                cb(new Error('Invalid email address'));
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: 'Enter your password.',
                        trigger: 'blur',
                    },
                ],
            },
            rememberUser: false,
            submitting: false,
        };
    },
    created() {
    },
    computed: {},
    methods: {
        ...mapActions(['setNavCollapseState']),
        ...mapActions(USER_WORKSTATION, ['setActiveUserWorkstation']),
        ...mapActions(AUTHENTICATION_STORE, ['login']),
        ...mapActions(USERS_STORE, ['getUser', 'saveUserState']),
        doLogin(e) {
            e.preventDefault();
            this.submitting = true;
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    const { username, password } = this.loginForm;
                    const passwordEncrypt = CryptoJS.AES.encrypt(password ?? '', CRYPTO_SECRET_KEY ?? '').toString();
                    this.login({
                        username,
                        password: passwordEncrypt
                    }).then(async (loginResponse) => {
                        if (loginResponse) {
                            const user = await this.getUser(loginResponse.user.id);
                            if (user) {
                                // store separately the saving of state so getUser action can be reuse on any page without affecting the user state;
                                this.saveUserState(user);
                                // set the default state for the sidenav collapse depending on the device
                                if (mobile.isMobile) {
                                    this.setNavCollapseState(true);
                                }
                                else {
                                    this.setNavCollapseState(false); // change to false
                                }
                                // only store few rerence for user info. This is to validate the page for route guards
                                setLocalStorageWithExpiry({
                                    roleId: user?.role?.id,
                                    userId: user.id,
                                    hasChosenRole: user.hasChosenRole,
                                    hasAccountSetup: user.hasAccountSetup,
                                    hasBusinessType: user.hasBusinessType
                                }, AUTH_USER, AUTH_SESSION_EXPIRY);
                                if (!user.hasChosenRole) {
                                    this.$router.push({ name: ACCOUNT_ROLE_SETUP });
                                }
                                else if (this.$route.query.returnUrl) {
                                    this.$router.push(this.$route.query.returnUrl);
                                }
                                else {
                                    this.$router.push({ name: DASHBOARD_ROUTE, params: { openUserReferAndEarnModal: 1, openUserBuildProfileModal: 1 } });
                                }
                            }
                        }
                        this.submitting = false;
                    })
                        .catch((error) => {
                        // redirect to reset form
                        if (error?.status === 404 && error?.data?.message && error?.data?.message.search('set')) {
                            this.$notify.warning({
                                title: 'Error',
                                message: error?.data?.message
                            });
                            this.navigateToForgotPasswordPage();
                            // If not verified redirect to verification page
                        }
                        else if (error?.status === 401 && error?.data?.message === 'User is not yet verified') {
                            this.$router.push({ name: VERIFY_REGISTRATION_ROUTE, params: { email: username } });
                        }
                        else {
                            this.$notify.error({
                                title: 'Error',
                                message: error?.data?.message
                            });
                        }
                        this.submitting = false;
                    });
                }
                else {
                    this.submitting = false;
                }
            });
        },
        navigateToRegistrationPage() {
            this.$router.push('/registration');
        },
        navigateToChangePasswordPage() {
            this.$router.push('/change-password');
        },
        navigateToForgotPasswordPage() {
            this.$router.push('/forgot-password');
        }
    }
});
